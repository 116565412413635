import React from 'react';

const Backend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Backend Development</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bxl-c-plus-plus"></i> {/* C# icon substitute */}
                        <h3 className="skills__name">C#</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-microsoft"></i> {/* ASP.NET Core API */}
                        <h3 className="skills__name">ASP.NET Core API</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-microsoft"></i> {/* ASP.NET Core MVC */}
                        <h3 className="skills__name">ASP.NET Core MVC</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bx-data"></i> {/* SQL Server */}
                        <h3 className="skills__name">SQL Server</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-firebase"></i> {/* Firebase */}
                        <h3 className="skills__name">Firebase</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxs-data"></i> {/* MySQL */}
                        <h3 className="skills__name">MySQL</h3>
                    </div>
                </div>
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bxs-component"></i> {/* OOP */}
                        <h3 className="skills__name">OOP</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bx-cube"></i> {/* Functional Programming */}
                        <h3 className="skills__name">Functional Programming</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bx-layer-plus"></i> {/* Entity Framework */}
                        <h3 className="skills__name">Entity Framework</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bx-layer"></i> {/* ADO.NET */}
                        <h3 className="skills__name">ADO.NET</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bx-filter-alt"></i> {/* LINQ */}
                        <h3 className="skills__name">LINQ</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Backend;
