import React from 'react';
import './about.css';
import CV from '../../assets/Abdulhamid___CV.pdf'
import Info from './Info'
import Lottie from 'lottie-react';

import Contact from '../../../src/animation/about1.json'

const About = () => {
    return(
        <section className="about section" id="about">
            <h2 className="section__title">About Me</h2>
            <span className="section__subtitle">My introduction</span>

            <div className="about__container container grid">
            <Lottie className="Lottie__contact"
                  animationData={Contact} // JSON data of your animation
                  loop={true}
                  autoplay={true}
                />
                <div className="about__data">
                    <Info />

                    <p className="about__description">
                        Full Stack Developer, I'm a full stack .Net Developer
                        I create simple and medium projects from zero,
                        using .Net framework.
                    </p>

                    <a download="" href={CV} className="about__button">Donwload CV
                    <i class='bx bx-file about__button-icon'></i>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default About;