import React from 'react';

const Frontend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Frontend Development</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bxl-html5"></i>
                        <h3 className="skills__name">HTML</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxs-file-css"></i>
                        <h3 className="skills__name">CSS</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-javascript"></i>
                        <h3 className="skills__name">JavaScript</h3>
                    </div>
                </div>
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bxl-react"></i>
                        <h3 className="skills__name">React</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-bootstrap"></i>
                        <h3 className="skills__name">Bootstrap</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxs-layer"></i>
                        <h3 className="skills__name">Razor Pages</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Frontend;
