import React from 'react';

const Technical = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Other Skills</h3>
            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bx-brain"></i>
                        <h3 className="skills__name">Problem Solving</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxs-keyboard"></i>
                        <div>
                            <h3 className="skills__name">Typing on Keyboard</h3>
                            <span className="skills__level">Advanced - 105WPM</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-git"></i>
                        <h3 className="skills__name">Git</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bxl-visual-studio"></i>
                        <h3 className="skills__name">VisualSVN</h3>
                    </div>
                    <div className="skills__data">
                        <i className="bx bx-server"></i>
                        <h3 className="skills__name">Hosting</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Technical;
