import React from 'react'
import './footer.css'

const Footer = () =>{
    return(
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Abdulhamid Shahade</h1>
                
                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">About</a>
                    </li>
                    <li>
                        <a href="#project" className="footer__link">Projects</a>
                    </li>
                    <li>
                        <a href="#service" className="footer__link">Services</a>
                    </li>
                </ul>

                <span className="version">version 1.0</span>
            
            
                <div className="footer__social">
                    <a href="https://www.facebook.com/hamidshahade" 
                       className="footer__social-link" target="_blank">
                       <i class="bx bxl-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/hamid_shahade/" 
                       className="footer__social-link" target="_blank">
                       <i class="bx bxl-instagram"></i>
                    </a>
                    <a href="https://x.com/HamidShahade" 
                       className="footer__social-link" target="_blank">
                       <i class="bx bxl-twitter"></i>
                    </a>
                </div>
                <span className="footer__copy">&#169; Shahade. All rights reserved</span>
            </div>
        </footer>
    )
}

export default Footer;