import React from 'react'


const Data = () => {
    return(
        <div className="home__data">
            <h1 className="home__title">
                Abdulhamid Shahade
            </h1>
            <h3 className="home__subtitle">
                Full Stack Developer
            </h3>
            <p className="home__description">
                I'm a junior full stack Developer
            </p>
            <a href="#contact" className="home__button">
                Mail Me!
                <i class='bx bx-envelope home__button-icon'></i>
            </a>
        </div>
    )
}

export default Data;