import React, { useState } from 'react';
import './project.css';

const Project = () => {
  const [toggleState, setToggleState] = useState(-1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="project section" id="project">
      <div>
        <h2 className="section__title">Projects</h2>
        <span className="section__subtitle">My Personal Projects</span>

        <div className="project__container container grid">
          {/* First project */}
          <div className="project__content">
            <div className="project__status">
              <span className="badge bg-success text-white">Version 1.0</span>
            </div>
            <div>
              <i className="bx bx-camera-movie project__icon"></i>
              <h3 className="project__title">Movie Tickets Web Application</h3>
              <span className="project__button" onClick={() => toggleTab(0)}>
                View More
                <i className="uil uil-arrow-right project__button-icon"></i>
              </span>
            </div>

            {/* Modal for the first project */}
            <div className={toggleState === 0 ? 'projects__modal active-modal' : 'projects__modal'}>
              <div className="projects__modal-content">
                <i className="uil uil-times projects__modal-close" onClick={() => toggleTab(-1)}></i>
                <h3 className="projects__modal-title">Movie Ticket Web Application</h3>
                <p className="projects__modal-description">
                  Website Line: <a href="https://movieland24.com">Movieland24</a>
                </p>
                <p className="projects__modal-description">
                  Website Overview: <a href="https://www.youtube.com/watch?v=iXXF7-H45c4&t=1001s">Youtube Overview</a>
                </p>
              </div>
            </div>
          </div>

          {/* Second project */}
          <div className="project__content">
            <div className="project__status">
              <span className="badge bg-success text-white">Version 1.0</span>
            </div>
            <div>
              <i className="bx bx-briefcase project__icon"></i>
              <h3 className="project__title">Portfolio Website</h3>
              <span className="project__button" onClick={() => toggleTab(1)}>
                View More
                <i className="uil uil-arrow-right project__button-icon"></i>
              </span>
            </div>

            {/* Modal for the second project */}
            <div className={toggleState === 1 ? 'projects__modal active-modal' : 'projects__modal'}>
              <div className="projects__modal-content">
                <i className="uil uil-times projects__modal-close" onClick={() => toggleTab(-1)}></i>
                <h3 className="projects__modal-title">React User Interface</h3>
                <p className="projects__modal-description">
                  Website link: <a href="https://hamidshahade.com">HamidShahade</a>
                </p>
              </div>
            </div>
          </div>

          {/* Third project */}
          <div className="project__content">
            <div className="project__status">
              <span className="badge bg-danger text-white">Working On</span>
            </div>
            <div>
              <i className="bx bx-movie-play project__icon"></i>
              <h3 className="project__title">Movie Ticket Web API</h3>
              <span className="project__button" onClick={() => toggleTab(2)}>
                View More
                <i className="uil uil-arrow-right project__button-icon"></i>
              </span>
            </div>

            {/* Modal for the third project */}
            <div className={toggleState === 2 ? 'projects__modal active-modal' : 'projects__modal'}>
              <div className="projects__modal-content">
                <i className="uil uil-times projects__modal-close" onClick={() => toggleTab(-1)}></i>
                <h3 className="projects__modal-title">ASP.NET</h3>
                <p className="projects__modal-description">
                  Developing ASP.NET apps with 1 year of experience.
                </p>
                <ul className="projects__modal-projects grid">
                  <li className="projects__modal-project">
                    <i className="uil uil-check-circle projects__modal-icon"></i>
                    <p className="projects__modal-info">ASP.NET Core MVC</p>
                  </li>
                  <li className="projects__modal-project">
                    <i className="uil uil-check-circle projects__modal-icon"></i>
                    <p className="projects__modal-info">ASP.NET Core API</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;
