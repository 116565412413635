import React, { useState } from 'react'
import './services.css'

const Services = () => {
    const [toggleState, setToggleState] = useState(-1);

    const toggleTab = (index) =>{
        setToggleState(index);
    }
    return(
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">What I offer</span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">Desktop <br /> Applications</h3>
                    </div>

                    <span className="services__button" onClick={()=>toggleTab(0)}>View More 
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState===0 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={()=>toggleTab(10)}></i>
                            <h3 className="services__modal-title">Windows Forms</h3>
                            <p className="services__modal-description">
                                Creating Windows Small/Medium Desktop Applications.
                            </p>

                            {/* <ul className="services__modal-services gird">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title">React <br /> User Interface</h3>
                    </div>

                    <span className="services__button" onClick={()=>toggleTab(1)}>View More 
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className= {toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={()=>toggleTab(10)}></i>
                            <h3 className="services__modal-title">React User Interface</h3>
                            <p className="services__modal-description">
                                Create React Small/Medium Projects.
                            </p>
 
                            {/* <ul className="services__modal-services gird">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">JaveScript </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">test 8</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info"></p>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div className="services__content">
                    <div>
                        <i className="uil uil-edit services__icon"></i>
                        <h3 className="services__title">ASP.NET <br />CORE</h3>
                    </div>

                    <span className="services__button" onClick={()=>toggleTab(2)}>View More
                    <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className = {toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={()=>toggleTab(10)}></i>
                            <h3 className="services__modal-title">ASP.NET</h3>
                            <p className="services__modal-description">
                                Making ASP.Net apps, with experience with 3 Years.
                            </p>

                            <ul className="services__modal-services gird">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Asp.NET Core MVC</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Asp.NET Core API</p>
                                </li>
                                {/* <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">test 13</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">test 14</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">test 15</p>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;